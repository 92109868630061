import Button from "@mui/material/Button";
import {
  DB_COLLECTION_NAME,
  STATUS,
  StatusTypes,
  TicketTypes,
} from "src/interfaces";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  jaJP,
} from "@mui/x-data-grid";
import * as CONF from "src/configs";
import { db } from "src/models/init-firebase";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

/**
 * ticketの状態をテキストに変換する
 */
const getStatusText = (status: StatusTypes) => {
  let statusText: StatusTypes | string = "";

  switch (status) {
    case STATUS.SKIP:
      statusText = "保留";
      break;
    case STATUS.WAIT:
      statusText = "順番待ち";
      break;
  }

  return statusText;
};

/**
 * 確認のダイアログを表示する
 */
const AlertDialog: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  handleAgree: () => void;
}> = ({ children, isOpen, handleClose, handleAgree }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">確認</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleAgree} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * チケットの操作ボタンの型
 */
type ButtonProps = {
  id: string;
  status: StatusTypes;
};

/**
 * 保留ボタン
 */
const SkipButton: React.FC<ButtonProps> = ({ id, status }) => {
  const [isOpen, setIsOpen] = useState(false);
  const disabled = status === STATUS.SKIP;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAgree = () => {
    (async () => {
      try {
        const ref = doc(db, DB_COLLECTION_NAME, id);
        await updateDoc(ref, { status: STATUS.SKIP });

        if (CONF.IS_DEBUG) {
          console.log("Updated status to skip", id);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        disabled={disabled}
      >
        保留
      </Button>
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleClose}
        handleAgree={handleAgree}
      >
        保留にしてもよろしいですか？
      </AlertDialog>
    </>
  );
};

/**
 * 受付ボタン
 */
const ReceptionButton: React.FC<ButtonProps> = ({ id, status }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAgree = () => {
    (async () => {
      try {
        const ref = doc(db, DB_COLLECTION_NAME, id);
        if (status !== STATUS.SKIP) {
          await updateDoc(ref, { status: STATUS.RECEIPT });
        }
        await deleteDoc(ref);

        if (CONF.IS_DEBUG) {
          console.log("Receipt and Deleted reserve ticket", id);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    setIsOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        受付
      </Button>
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleClose}
        handleAgree={handleAgree}
      >
        受付してもよろしいですか？
      </AlertDialog>
    </>
  );
};

/**
 * 削除ボタン
 */
const DeleteButton: React.FC<ButtonProps> = ({ id, status }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAgree = () => {
    (async () => {
      try {
        const ref = doc(db, DB_COLLECTION_NAME, id);
        if (status !== STATUS.SKIP) {
          await updateDoc(ref, { status: STATUS.DELETE });
        }
        await deleteDoc(ref);

        if (CONF.IS_DEBUG) {
          console.log("Deleted reserve ticket", id);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    setIsOpen(false);
  };

  return (
    <>
      <Button variant="outlined" color="error" onClick={handleOpen}>
        削除
      </Button>
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleClose}
        handleAgree={handleAgree}
      >
        削除してもよろしいですか？
      </AlertDialog>
    </>
  );
};

/**
 * Props
 */
type TicketsControlViewProps = {
  tickets: TicketTypes[];
};

/**
 * Ticketを操作するテーブル
 */
const ControlTicketsView: React.FC<TicketsControlViewProps> = ({ tickets }) => {
  const rows = tickets;

  const colums: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 220,
      hide: !CONF.IS_DEBUG,
    },
    { field: "number", headerName: "No" },
    {
      field: "status",
      headerName: "状態",
      valueGetter: (params) => getStatusText(params.row.status),
    },
    {
      field: "skipButton",
      headerName: "保留",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <SkipButton id={params.id as string} status={params.row.status} />
      ),
    },
    {
      field: "receptionButton",
      headerName: "受付",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <ReceptionButton id={params.id as string} status={params.row.status} />
      ),
    },
    {
      field: "deleteButton",
      headerName: "削除",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <DeleteButton id={params.id as string} status={params.row.status} />
      ),
    },
  ];

  return (
    <Box minHeight="400px" height="70vh">
      <DataGrid
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={colums}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default ControlTicketsView;
