import { Container, List, ListItem, Typography } from "@mui/material";
import ControlTicketsView from "src/components/ControlTicketsView";
import { useListenTicketsAllDB } from "src/hooks/useListenTicketsDB";
import { STATUS } from "src/interfaces";

const Admin = () => {
  const tickets = useListenTicketsAllDB();

  const numOfWait = tickets.filter(
    (ticket) => ticket.status === STATUS.WAIT
  ).length;

  return (
    <Container maxWidth="md">
      <Typography variant="h3" component="h1">
        受付管理画面
      </Typography>

      <List>
        <ListItem>
          <Typography>現在の待ち人数: {numOfWait}</Typography>
        </ListItem>
      </List>

      <ControlTicketsView tickets={tickets} />
    </Container>
  );
};

export default Admin;
