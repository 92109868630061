// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCH-K7TRUQ73e3yekeLj9v53RpJNyv0_GU",
  authDomain: "oimo-reserve-system.firebaseapp.com",
  projectId: "oimo-reserve-system",
  storageBucket: "oimo-reserve-system.appspot.com",
  messagingSenderId: "927259182099",
  appId: "1:927259182099:web:6ced27673730c71d22ebe6",
};

// Initialize Firebase
initializeApp(firebaseConfig);
const db = getFirestore();

export { db };
