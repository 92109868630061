import { Box, List, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import LogoutButton from "src/components/LogoutButton";

const UserNav = () => {
  return (
    <Box>
      <List>
        <ListItem>
          <Link to="/reserve">受付画面</Link>
        </ListItem>
        <ListItem>
          <Link to="/admin">管理画面</Link>
        </ListItem>
      </List>

      <LogoutButton />
    </Box>
  );
};

export default UserNav;
