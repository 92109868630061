import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Tickets, Ticket } from "./pages/Tickets";
import Reserve from "./pages/Reserve";
import Admin from "./pages/Admin";
import DebugPanel from "./components/DebugPanel";
import NotFound from "./pages/NotFound";
import * as CONF from "./configs";
import Login from "./pages/Login";
import { useAuthContext } from "./contexts/AuthContext";
import UserNav from "./pages/UserNav";

function App() {
  const { user } = useAuthContext();

  if (user === undefined) return <div>Loading...</div>;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="tickets" element={<Tickets />}>
          <Route index element={<NotFound />} />
          <Route path=":id" element={<Ticket />} />
        </Route>
        <Route path="reserve" element={user ? <Reserve /> : <NotFound />} />
        <Route path="admin" element={user ? <Admin /> : <NotFound />} />
        <Route path="usernav" element={user ? <UserNav /> : <NotFound />} />
        <Route
          path="login"
          element={user ? <Navigate to="/usernav" /> : <Login />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {CONF.IS_DEBUG_PANEL && <DebugPanel />}
    </BrowserRouter>
  );
}

export default App;
