import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { DB_COLLECTION_NAME, STATUS, TICKET_KEY } from "src/interfaces";
import { db } from "./init-firebase";
import * as CONF from "src/configs";
/**
 * 予約してチケットを発行する
 */
const reserveTicket = async (nextNumber: number) => {
  let newId = null;

  try {
    const resultAddDoc = await addDoc(collection(db, DB_COLLECTION_NAME), {
      [TICKET_KEY.NUMBER]: nextNumber,
      [TICKET_KEY.STATUS]: STATUS.WAIT,
      [TICKET_KEY.CREATED_AT]: serverTimestamp(),
    });

    newId = resultAddDoc.id;
    if (CONF.IS_DEBUG) {
      console.log("DB: Add Document written with ID", newId);
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }

  return newId;
};

export default reserveTicket;
