import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { DB_COLLECTION_NAME, TicketTypes } from "src/interfaces";
import getNumbersWait from "src/models/getNumbersWait";
import { db } from "src/models/init-firebase";

const Tickets = () => {
  return <Outlet />;
};

/**
 * それぞれの受付チケットの表示を行う
 */
const Ticket = () => {
  const [ticket, setTicket] = useState<TicketTypes>();
  const [numOfWait, setNumOfWait] = useState<number>();

  const [canReload, setCanReload] = useState<boolean>(false);
  const timer = useRef<number>();

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      if (id) {
        const docRef = doc(db, DB_COLLECTION_NAME, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data() as TicketTypes;

          setTicket(data);

          const numbersWait = await getNumbersWait();
          const numOfWait = numbersWait.indexOf(data.number);

          if (numOfWait < 0) {
            setNumOfWait(0);
          } else {
            setNumOfWait(numOfWait);
          }
        }
      }
    })();
  }, [id]);

  useEffect(() => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      setCanReload(true);
    }, 5000);

    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, []);

  const handleClick = () => {
    window.location.reload();
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        minHeight="100vh"
      >
        <Grid item>
          <Typography variant="h5" component="h1">
            受付番号
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" component="p" fontWeight="bold">
            No.{ticket?.number}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="p">
            あと
            <Box component="span" fontSize="1.6em">
              {numOfWait}
            </Box>
            人後です
          </Typography>
        </Grid>
        <Grid item>
          <Typography>あと1人になりましたら、お店までお越しください</Typography>
        </Grid>

        <Grid item mt={4}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disabled={!canReload}
            onClick={handleClick}
          >
            更新
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export { Tickets, Ticket };
