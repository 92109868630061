import { Timestamp } from "firebase/firestore";

export const DB_COLLECTION_NAME = "tickets";
export const DB_CURRENT_COL_NAME = "current";
export const DB_CURRENT_DOC_NAME = "current";

export const STATUS = {
  RECEIPT: "receipt",
  SKIP: "skip",
  WAIT: "wait",
  DELETE: "delete",
} as const;
export type StatusTypes = typeof STATUS[keyof typeof STATUS];

export const TICKET_KEY = {
  ID: "id",
  NUMBER: "number",
  STATUS: "status",
  CREATED_AT: "createdAt",
} as const;

export type TicketTypes = {
  [TICKET_KEY.ID]: string;
  [TICKET_KEY.NUMBER]: number;
  [TICKET_KEY.STATUS]: StatusTypes;
  [TICKET_KEY.CREATED_AT]: Timestamp;
};

export const CURRENT_KEY = {
  NUMBERS_WAIT: "numbersWait",
} as const;

export type CurrentTypes = {
  [CURRENT_KEY.NUMBERS_WAIT]: number[];
};
