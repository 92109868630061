import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  DB_COLLECTION_NAME,
  STATUS,
  TicketTypes,
  TICKET_KEY,
} from "src/interfaces";
import { db } from "src/models/init-firebase";
import * as CONF from "src/configs";
import setNumbersWait from "src/models/setNumbersWait";

const ticketsRef = collection(db, DB_COLLECTION_NAME);

const useListenTicketsAllDB = () => {
  const [tickets, setTickets] = useState<TicketTypes[]>([]);

  useEffect(() => {
    const ticketsRef = collection(db, DB_COLLECTION_NAME);
    const q = query(ticketsRef, orderBy("number", "asc"));

    const unsbscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const isLocal = querySnapshot.metadata.hasPendingWrites;

        const ticketsList: TicketTypes[] = [];
        const numbersWaitList: number[] = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data() as TicketTypes;
          data.id = doc.id;

          ticketsList.push(data);

          if (isLocal) {
            if (data.status === STATUS.WAIT) {
              numbersWaitList.push(data.number);
            }
          }
        });

        setTickets(ticketsList);

        // 待機中のチケット番号を更新する
        if (isLocal) {
          setNumbersWait(numbersWaitList);

          if (CONF.IS_DEBUG) {
            console.log(
              "Updated current wait tickets numbers",
              numbersWaitList
            );
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsbscribe();
    };
  }, []);

  return tickets;
};

/**
 * 待機中のチケットをリッスンする
 */
const useListenTicketsWaitDB = () => {
  const [ticketsWait, setTicketsWait] = useState<TicketTypes[]>();

  useEffect(() => {
    const q = query(
      ticketsRef,
      where(TICKET_KEY.STATUS, "==", STATUS.WAIT),
      orderBy(TICKET_KEY.NUMBER, "asc")
    );

    const unsbscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const isLocal = querySnapshot.metadata.hasPendingWrites;

        const ticketsList: Array<TicketTypes> = [];
        const ticketsNumbersList: number[] = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data() as TicketTypes;
          data.id = doc.id;

          ticketsList.push(data);

          if (isLocal) {
            ticketsNumbersList.push(data.number);
          }
        });

        setTicketsWait(ticketsList);

        // 待機中のチケット番号を更新する
        if (isLocal) {
          setNumbersWait(ticketsNumbersList);

          if (CONF.IS_DEBUG) {
            console.log(
              "Updated current wait tickets numbers",
              ticketsNumbersList
            );
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsbscribe();
    };
  }, []);

  return ticketsWait;
};

/**
 * 次のチケット番号をリッスンする
 */
const useListenNextReserveNumberDB = () => {
  const [nextReserveNumber, setNextReserveNumber] = useState<number>();

  useEffect(() => {
    const q = query(ticketsRef, orderBy("number", "desc"), limit(1));

    const unsbscribe = onSnapshot(
      q,
      (querySnapshot) => {
        let nextNumber = 1;
        if (querySnapshot.size > 0) {
          const doc = querySnapshot.docs[0].data() as TicketTypes;
          nextNumber = doc.number + 1;

          setNextReserveNumber(nextNumber);

          // debug log
          if (CONF.IS_DEBUG) {
            const source = querySnapshot.metadata.hasPendingWrites
              ? "Local"
              : "Server";
            console.log("Updated next reserve number:", nextNumber, source);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );

    setNextReserveNumber(1);

    return () => {
      unsbscribe();
    };
  }, []);

  return nextReserveNumber;
};

/**
 * 次に呼ばれるチケット番号をリッスンする。
 * ロード中ならundefinedを、もし呼ばれるチケットがない場合-1を返す
 */
const useListenNextCallNumberDB = () => {
  const [nextCallNumber, setNextCallNumber] = useState<number>();

  useEffect(() => {
    const q = query(
      ticketsRef,
      where(TICKET_KEY.STATUS, "==", STATUS.WAIT),
      orderBy("number", "asc"),
      limit(1)
    );

    const unsbscribe = onSnapshot(
      q,
      (querySnapshot) => {
        if (querySnapshot.size > 0) {
          const doc = querySnapshot.docs[0].data() as TicketTypes;
          const nextNumber = doc.number;

          setNextCallNumber(nextNumber);

          // debug log
          if (CONF.IS_DEBUG) {
            const source = querySnapshot.metadata.hasPendingWrites
              ? "Local"
              : "Server";
            console.log("Updated next call number", nextNumber, source);
          }
        } else {
          setNextCallNumber(-1);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsbscribe();
    };
  }, []);

  return nextCallNumber;
};

export {
  useListenTicketsAllDB,
  useListenTicketsWaitDB,
  useListenNextReserveNumberDB,
  useListenNextCallNumberDB,
};
