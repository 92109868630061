import { doc, setDoc } from "firebase/firestore";
import {
  CURRENT_KEY,
  DB_CURRENT_COL_NAME,
  DB_CURRENT_DOC_NAME,
} from "src/interfaces";
import { db } from "./init-firebase";

/**
 * 順番待ち中の番号を更新する
 */
const setNumbersWait = async (numbersWait: number[]) => {
  const ref = doc(db, DB_CURRENT_COL_NAME, DB_CURRENT_DOC_NAME);
  await setDoc(ref, {
    [CURRENT_KEY.NUMBERS_WAIT]: numbersWait,
  });
};

export default setNumbersWait;
