import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ReserveButton from "src/components/ReserveButton";
import {
  useListenNextReserveNumberDB,
  useListenTicketsWaitDB,
} from "src/hooks/useListenTicketsDB";

const Reserve = () => {
  const ticketsWait = useListenTicketsWaitDB();
  const nextReserveNumber = useListenNextReserveNumberDB();

  let nextCallNumberText = "--";
  if (ticketsWait) {
    if (ticketsWait.length > 0) {
      const nextCallNumber = ticketsWait
        .map((ticket) => ticket.number)
        .reduce((a, b) => Math.min(a, b));

      nextCallNumberText = nextCallNumber ? nextCallNumber.toString() : "--";
    }
  }

  const numOfWait = ticketsWait?.length;

  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        minHeight="100vh"
      >
        <Grid item>
          <Typography variant="h2" component="h1">
            受付
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" component="p">
            現在{" "}
            <Box component="span" fontSize="1.6em" fontWeight="fontWeightBold">
              {numOfWait}
            </Box>{" "}
            人待ち
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" component="p" textAlign="center">
            次のお呼び出し
            <br />
            <Box component="span" fontSize="1.6em">
              No.{nextCallNumberText}
            </Box>
          </Typography>
        </Grid>

        <Grid item>
          <ReserveButton nextReserveNumber={nextReserveNumber} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Reserve;
