import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { Box } from "@mui/system";
import * as CONF from "src/configs";
import LogoutButton from "src/components/LogoutButton";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const isDisabledSubmit = !email || !password;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        if (CONF.IS_DEBUG) {
          console.log("Signed in", user);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        setMessage("ログインに失敗しました");
        if (CONF.IS_DEBUG) {
          console.log("Error", errorCode, errorMessage);
        }
      });
  };

  const handleChangeEmail = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPassword(event.target.value);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" component="h1">
        Login
      </Typography>

      <Grid
        component="form"
        container
        mt={6}
        direction="column"
        gap={4}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Grid item>
          <TextField
            label="Email"
            type="email"
            required
            fullWidth
            onChange={handleChangeEmail}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Password"
            type="password"
            required
            fullWidth
            onChange={handleChangePassword}
          />
        </Grid>
        <Grid item textAlign="right">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isDisabledSubmit}
          >
            認証
          </Button>
        </Grid>
      </Grid>

      <Typography mt={4}>{message}</Typography>

      <Box mt={4} textAlign="right">
        <LogoutButton />
      </Box>
    </Container>
  );
};

export default Login;
