import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import reserveTicket from "src/models/reserveTicket";
import QRCode from "qrcode";
import { Box } from "@mui/system";

/**
 * 予約受付のダイアログProps
 */
type ReserveDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  newId: string;
};

/**
 * 予約受付のダイアログ
 */
const ReserveDialog: React.FC<ReserveDialogProps> = ({
  isOpen,
  handleClose,
  newId,
}) => {
  const host = window.location.protocol + window.location.host;
  const url = `${host}/tickets/${newId}`;

  const svgWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      // const qrImage = await QRCode.toDataURL(url);
      const qrImage = await QRCode.toString(url, { type: "svg" });

      if (svgWrapperRef.current) {
        svgWrapperRef.current.innerHTML = qrImage;
      }
    })();
  }, [url]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ fontSize: "2rem", textAlign: "center" }}>
        受付完了
      </DialogTitle>

      <DialogContent>
        <Typography variant="h5" component="p" textAlign="center">
          QRコードを読み込んでください
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            sx={{ width: "300px", height: "300px" }}
            ref={svgWrapperRef}
          ></Grid>
        </Grid>

        <Box textAlign="center">
          <Button variant="outlined" size="large" onClick={handleClose}>
            戻る
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

/**
 * 予約ボタンのProps
 */
type ReserveButtonProps = {
  nextReserveNumber: number | undefined;
};

/**
 * 予約ボタン
 */
const ReserveButton: React.VFC<ReserveButtonProps> = ({
  nextReserveNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newId, setNewId] = useState("");
  const disabled = nextReserveNumber === undefined;

  const handleReserve = async () => {
    if (nextReserveNumber) {
      const newId = await reserveTicket(nextReserveNumber);

      if (newId) {
        setNewId(newId);
        setIsOpen(true);
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        size="large"
        disabled={disabled}
        onClick={handleReserve}
      >
        受付する
      </Button>

      <ReserveDialog isOpen={isOpen} handleClose={handleClose} newId={newId} />
    </>
  );
};

export default ReserveButton;
