import { Button } from "@mui/material";
import { getAuth } from "firebase/auth";
import * as CONF from "src/configs";

const LogoutButton = () => {
  const handleLogout = () => {
    const auth = getAuth();

    auth
      .signOut()
      .then(() => {
        if (CONF.IS_DEBUG) {
          console.log("Signed out");
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return <Button onClick={handleLogout}>認証を解除する</Button>;
};

export default LogoutButton;
