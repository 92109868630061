import { doc, getDoc } from "firebase/firestore";
import {
  CurrentTypes,
  CURRENT_KEY,
  DB_CURRENT_COL_NAME,
  DB_CURRENT_DOC_NAME,
} from "src/interfaces";
import { db } from "./init-firebase";

const getNumbersWait = async () => {
  const docRef = doc(db, DB_CURRENT_COL_NAME, DB_CURRENT_DOC_NAME);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data() as CurrentTypes;

    return data[CURRENT_KEY.NUMBERS_WAIT];
  }

  return [];
};

export default getNumbersWait;
