import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import * as CONF from "src/configs";

const AuthContext = createContext(
  {} as {
    user: string | undefined;
  }
);

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<string>();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(uid);

        if (CONF.IS_DEBUG) {
          console.log("AuthStateChanged -> Sign in", uid);
        }
      } else {
        setUser("");

        if (CONF.IS_DEBUG) {
          console.log("AuthStateChanged -> Sign out");
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
